import React from "react"
import Layout from "../components/layout"
// import "react-responsive-carousel/lib/styles/carousel.min.css"
import TechnicalData from "../components/product_tech_data"
import ProductDetail from "../components/product_detail"
import refuse_compactor from "../../public/static/images/products/refuse_compactor.jpg"

function RefuseCompactor() {

  const prodDetail = {
    "name": "Refuse Compactor",
    "detail": {
      "about" : ["Refuse compactors are being manufactured with high strength, reliability, superior quality, easy operation & utilization for its end use."],
      "advantages": [
        "Refuse compactors are highly productive, easy operational and hygienic system to help in keeping a city clean.",
        "Provision for carrying garbage containers of different sizes by hydraulically arrangement according to change in its rear situated arm size & dimeter",
        "The tailgate & ejector system can dump all garbage in as single stroke by hydraulic operated cylinder & control lever that is helpful to minimize time for unloading of garbage and also less human efforts.",
        "The packer system that is sliding on upper plate will squeeze the garbage directly from hopper to body automatically by lever."
      ]
    },
    "tech_data": {
      "column1": [
        "Code",
        "Chassis",
        "Body Size",
        "Thickness",
        "Lifting Size (cum)",
        "Hydraulic Cylinder",
        "Hydraulic Pump",
        "Hydraulic Hoses",
        "Power Take Off",
        "Hydraulic Tank",
        "Operational"
      ],
      "column2": [
        "IE - TPAC",
        "9 Ton, 12 Ton, 16 Ton or Customized 7 Cum, 9 Cum, 14 Cum or (Customized)",
        "(IS:2062)",
        "3 mm, 4 mm, 5mm ,6mm) Etc.",
        "(1.1) (1.8) / UBL Etc.",
        "Make DENTAL / IEPL",
        "High Pressure / Vane Pump Upto LPM",
        "Hoses upto ¾“",
        "Suitable Power Transfer from vehicle's PTO",
        "110-200 Ltrs. capacity",
        "Hydraulic operations for Ejector Plate, Packer Plate, Sliding Plate, Tailgate, Lifting of container & Bin arrangement done by heavy duty Hydraulic operated Cylinder & control lever."
      ]
    }
  }

  return (
    <Layout className="semi-automatic">
      <ProductDetail name={prodDetail.name} detail={prodDetail.detail} image={refuse_compactor} />

      {prodDetail.tech_data !== undefined ? <TechnicalData techData={prodDetail.tech_data} /> : <></>}
    </Layout>
  )
}

export default RefuseCompactor
